import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  raleway,
  mainWhite,
  mainBlack,
  mainRed,
  lightRed,
  lessBlack,
  innerWidth,
  transHover,
  screen,
  dirtyWhite,
} from "../components/common/variables";
import colouredLogo from "../images/coloured-logo.svg";
import logo from "../images/coloured-logo.png";
import Obfuscate from "react-obfuscate";

const organisers = [
  {
    image: "/images/PhaneendraA.png",
    title: "Phaneendra Arigachetta",
  },
  {
    image: "/images/SeshadriAvula.png",
    title: "Seshadri Avula",
  },
  {
    image: "/images/AnaAD.png",
    title: "Ana Alvarez Deza",
  },
  {
    image: "/images/CSGan.png",
    title: "Choon Soon (CS) Gan",
  },
  {
    image: "/images/JasonL.png",
    title: "Jason Lim",
  },
  {
    image: "/images/MelodyL.png",
    title: "Melody Lwo",
  },
  {
    image: "/images/BeckyS.png",
    title: "Becky Sweetman",
  },
  {
    image: "/images/ManishThaduri.png",
    title: "Manish Thaduri",
  },
  {
    image: "/images/JohanYu.png",
    title: "Johan Yu",
  },
];

const Wrapper = styled.div`
  p {
    color: ${mainBlack};
  }

  .banner {
    background-image: url(/images/background.png);
    background-position: calc(400px + 50vw) 0;
    background-size: cover;
    color: ${mainWhite};
    font-family: ${raleway};
    text-align: center;
    padding: 220px 0 172px 0;
    position: relative;
    @media ${screen.small} {
      background-size: cover;
      background-position: -607px 0;
      padding: 320px 0 255px 0;
    }
    @media ${screen.medium} {
      padding: 323px 0 242px 0;
      background-position: center;
    }
    @media ${screen.large} {
      padding: 320px 0 255px 0;
    }

    &__heading {
      font-size: 2.1rem;
      font-weight: 600;
      line-height: 1.3;
      text-shadow: 1px 2px 1px #000;
      position: relative;
      z-index: 2;
      @media ${screen.small} {
        font-size: 4.42rem;
        text-shadow: 2px 3px 2px #000;
      }
      @media ${screen.medium} {
        font-size: 5.62rem;
      }
    }

    &__subheading {
      font-size: 1.4rem;
      font-weight: 500;
      text-shadow: 1px 1px 2px #000;
      position: relative;
      z-index: 2;
      @media ${screen.small} {
        font-size: 2.6rem;
        text-shadow: 2px 2px 4px #000;
      }
      @media ${screen.medium} {
        font-size: 3.1rem;
      }
    }

    &::after {
      content: "";
      background: rgba(12, 29, 48, 0.6);
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  .intro {
    display: flex;
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 55px 25px 55px 25px;
    flex-direction: column;
    @media ${screen.small} {
      padding: 55px 35px;
      flex-direction: row;
    }
    @media ${screen.large} {
      padding: 70px 0;
    }

    &__text {
      color: ${lessBlack};
      font-family: ${raleway};
      order: 1;
      @media ${screen.small} {
        padding-right: 50px;
        order: 0;
      }
      @media ${screen.medium} {
        padding-right: 100px;
      }

      .intro-heading {
        font-size: 1.35rem;
        font-weight: 900;
        @media ${screen.small} {
          font-size: 2.4rem;
        }
        @media ${screen.medium} {
          font-size: 2.4rem;
        }
      }

      .intro-description {
        margin-top: 12px;
        @media ${screen.small} {
          margin-top: 16px;
        }

        h2 {
          font-size: 1.3rem;
          font-weight: 500;
          margin-bottom: 5px;
          @media ${screen.small} {
            font-size: 1.49rem;
          }
        }
        p {
          font-size: 1.1rem;
          font-weight: 300;
          @media ${screen.small} {
            font-size: 1.15rem;
          }
          a {
            color: ${lessBlack};
            font-size: 1.1rem;
            font-weight: 300;
            @media ${screen.small} {
              font-size: 1.15rem;
            }
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }

    &__brand {
      max-width: 140px;
      width: 100%;
      margin-bottom: 20px;
      @media ${screen.small} {
        margin-bottom: 0;
        max-width: 175px;
      }
    }
  }

  .organiser-wrapper {
    font-family: ${raleway};
    max-width: ${innerWidth};
    margin: 0 auto 55px auto;
    padding: 0 25px 40px 25px;
    flex-direction: column;
    @media ${screen.small} {
      padding: 0 35px;
      margin: 0 auto 70px auto;
    }
    @media ${screen.large} {
      padding: 0;
    }

    .organiser {
      &__heading {
        color: ${lessBlack};
        border-radius: 11px 11px 0 0;
        font-size: 1.2rem;
        font-weight: 900;
        font-family: ${raleway};
        @media ${screen.small} {
          font-size: 1.52rem;
        }

        span {
          font-weight: 300;
        }
      }

      &__list {
        display: flex;
        flex-wrap: wrap;
        padding: 16px 0 0;
        gap: 24px;

        .organiser-item {
          width: calc(50% - 12px);
          @media ${screen.small} {
            width: calc(33.33% - 40px);
          }
          @media ${screen.medium} {
            width: calc(25% - 40px);
          }

          &__img {
            border-radius: 11px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
            }
          }

          &__name {
            color: ${lessBlack};
            font-weight: 700;
            font-size: 1.15rem;
            margin-top: 5px;
            padding-right: 10px;
            line-height: 1.35;
            text-align: center;
            @media ${screen.small} {
              margin-top: 8px;
              font-size: 1.2rem;
              padding-right: 20px;
            }
          }
        }
      }
    }
  }

  .interest-wrapper {
    background: ${mainRed};

    .interest {
      display: flex;
      max-width: ${innerWidth};
      margin: 0 auto;
      padding: 0 25px 0 25px;
      flex-direction: column;
      @media ${screen.small} {
        padding: 0 0 0 35px;
        flex-direction: row;
      }
      @media ${screen.large} {
        padding: 0;
      }

      &__text {
        font-family: ${raleway};
        padding: 20px 0 55px 0;
        width: 100%;
        order: 1;
        @media ${screen.small} {
          width: 60%;
          padding: 55px 0;
          order: 0;
        }
        @media ${screen.medium} {
          padding: 70px 0;
        }

        .interest-heading {
          font-size: 1.2rem;
          font-weight: 900;
          text-transform: uppercase;
          margin-bottom: 3px;
          @media ${screen.small} {
            font-size: 1.52rem;
            margin-bottom: 5px;
          }
        }

        .interest-description {
          p {
            font-size: 1.1rem;
            font-weight: 300;
            margin-bottom: 16px;
            @media ${screen.small} {
              font-size: 1.15rem;
            }
          }
        }

        .contact-link {
          background: ${mainWhite};
          border-radius: 8px;
          color: ${mainBlack};
          display: table;
          font-weight: 600;
          font-size: 0.95rem;
          padding: 11px 42px;
          margin-top: 15px;
          transition: ${transHover};
          @media ${screen.small} {
            font-size: 1rem;
            padding: 13px 57px;
            margin-top: 32px;
          }
          @media ${screen.withCursor} {
            &:hover {
              background: ${mainBlack};
              color: ${mainWhite};
            }
          }
        }
      }

      &__featured-img {
        background-image: url(/images/team-photo.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
        margin-top: 55px;
        min-height: 230px;
        @media ${screen.small} {
          width: 40%;
          margin-left: 50px;
          margin-top: 0;
        }
        @media ${screen.medium} {
          margin-left: 100px;
        }
      }
    }
  }

  .event-wrapper {
    .event {
      border-radius: 22px;
      display: flex;
      max-width: ${innerWidth};
      margin: 55px 25px;
      overflow: hidden;
      flex-direction: column;
      @media ${screen.medium} {
        margin: 55px auto;
      }

      h2 {
        font-size: 1.563rem;
        font-weight: 900;
        color: ${lessBlack};
        text-transform: uppercase;
      }

      &__text {
        padding: 30px 0 25px 0;
        font-family: ${raleway};
        width: 100%;
        @media ${screen.small} {
          width: 60%;
          padding: 55px 0 55px 0;
        }
        @media ${screen.medium} {
          padding: 70px 0 70px 0;
        }

        .event-heading {
          color: ${mainBlack};
          font-size: 1.6rem;
          font-weight: 500;
          @media ${screen.small} {
            font-size: 2.45rem;
          }
        }

        img {
          width: 360px;
          height: 265px;
          margin-top: 24px;
        }

        .event-description {
          margin-top: 8px;
          @media ${screen.small} {
            margin-top: 16px;
          }

          p {
            font-size: 1.1rem;
            font-weight: 300;
            margin-bottom: 16px;
            @media ${screen.small} {
              font-size: 1.15rem;
            }
          }
        }

        a {
          width: 185px;
          height: 34px;
          background-color: ${mainRed};
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: ${mainWhite};
          text-transform: uppercase;
          font-weight: 500;
          font-size: 14px;
          margin-top: 13px;
        }

        .event-location {
          display: table;
          color: ${mainBlack};
          font-weight: 600;
          font-size: 1.1rem;
          line-height: 1.3;
          transition: ${transHover};
          @media ${screen.small} {
            white-space: pre;
            font-size: 1.15rem;
          }
          @media ${screen.withCursor} {
            &:hover {
              color: ${mainRed};
            }
          }
        }
      }

      &__featured-img {
        background-image: ${props => `url(${props.eventURL})`};
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        min-height: 230px;
        width: 100%;
        @media ${screen.small} {
          margin-left: 50px;
          width: 40%;
        }
        @media ${screen.medium} {
          margin-left: 100px;
        }
      }
    }
  }

  .speaker-wrapper {
    font-family: ${raleway};
    color: ${lessBlack};

    .speaker {
      max-width: calc(${innerWidth});
      width: 100%;
      margin: 0 auto;
      padding: 0 25px 39px 25px;
      @media ${screen.small} {
        padding: 0 35px 35px 35px;
      }
      @media ${screen.large} {
        padding: 0 0 50px 0;
      }

      &__top {
        .top-speaker-heading {
          font-size: 1.2rem;
          font-weight: 900;
          margin-bottom: 3px;
          @media ${screen.small} {
            font-size: 1.52rem;
            margin-bottom: 5px;
          }
        }

        .top-speaker-subheading {
          color: ${lessBlack};
          font-size: 1.1rem;
          font-weight: 300;
          @media ${screen.small} {
            font-size: 1.15rem;
          }
        }

        .top-list {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -11px;
          @media ${screen.small} {
            margin: 0;
          }

          &__item {
            margin-left: 11px;
            margin-right: 11px;
            margin-top: 11px;
            margin-bottom: 11px;
            width: calc(50% - 22px);
            @media ${screen.small} {
              margin-left: 0;
              margin-right: 40px;
              margin-top: 20px;
              margin-bottom: 20px;
              width: 26%;
            }

            .speaker-img {
              cursor: pointer;

              img {
                border-radius: 12px;
                width: 100%;
              }
            }

            .speaker-name {
              cursor: pointer;
              font-weight: 700;
              font-size: 1.12rem;
              margin-top: 3px;
              padding-right: 10px;
              @media ${screen.small} {
                margin-top: 5px;
                font-size: 1.2rem;
                padding-right: 20px;
              }
            }

            .speaker-company {
              font-size: 0.82rem;
              padding-right: 10px;
              @media ${screen.small} {
                font-size: 0.92rem;
                padding-right: 20px;
              }
            }
          }
        }
      }

      &__bottom {
        margin-top: 35px;

        .bottom-speaker-heading {
          font-size: 1.2rem;
          font-weight: 900;
          margin-bottom: 3px;
          @media ${screen.small} {
            font-size: 1.52rem;
            margin-bottom: 5px;
          }
        }

        .bottom-speaker-subheading {
          color: ${lessBlack};
          font-size: 1.1rem;
          font-weight: 300;
          @media ${screen.small} {
            font-size: 1.15rem;
          }
        }

        .bottom-list {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -11px;
          @media ${screen.small} {
            margin: 0 -20px;
          }

          &__item {
            margin: 11px;
            width: calc(50% - 22px);
            @media ${screen.small} {
              margin: 20px;
              width: calc(25% - 40px);
            }
            .speaker-img {
              cursor: pointer;
              img {
                border-radius: 12px;
                width: 100%;
              }
            }

            .speaker-name {
              cursor: pointer;
              font-weight: 700;
              font-size: 1.12rem;
              margin-top: 3px;
              padding-right: 10px;
              @media ${screen.small} {
                margin-top: 5px;
                font-size: 1.2rem;
                padding-right: 20px;
              }
            }

            .speaker-company {
              font-size: 0.82rem;
              padding-right: 10px;
              @media ${screen.small} {
                font-size: 0.92rem;
                padding-right: 20px;
              }
            }
          }
        }
      }
    }
  }

  .contact-speaker {
    max-width: calc(${innerWidth});
    font-family: ${raleway};
    width: 100%;
    margin: 0 auto;
    padding: 0 25px 55px 25px;
    @media ${screen.small} {
      padding: 0 35px 35px 35px;
    }
    @media ${screen.large} {
      padding: 0 0 70px 0;
    }

    &__heading {
      font-size: 1.2rem;
      font-weight: 900;
      margin-bottom: 3px;
      @media ${screen.small} {
        font-size: 1.52rem;
        margin-bottom: 5px;
      }
    }

    &__description {
      font-size: 1.1rem;
      font-weight: 300;
      @media ${screen.small} {
        font-size: 1.15rem;
      }
    }

    &__cta-link {
      background: ${mainRed};
      border-radius: 8px;
      color: ${mainWhite};
      display: table;
      font-weight: 600;
      font-size: 0.95rem;
      padding: 11px 42px;
      margin-top: 15px;
      transition: ${transHover};
      @media ${screen.small} {
        font-size: 1rem;
        padding: 13px 57px;
        margin-top: 32px;
      }
      @media ${screen.withCursor} {
        &:hover {
          background: ${lightRed};
        }
      }
    }
  }
`;

const IndexPage = () => {
  return (
    <Layout isNew>
      <SEO
        title="Singapore Dreamin’ 2023"
        description={
          "Singapore Dreamin’ is a nonprofit event and so we are seeking sponsorship from the Salesforce community."
        }
        image={logo}
      />
      <Wrapper>
        <section className="banner" id="top">
          <h1 className="banner__heading">Singapore Dreamin’ 2023</h1>
          <h2 className="banner__subheading"></h2>
        </section>
        <section className="intro" id="about">
          <div className="intro__text">
            <h1 className="intro-heading">TRAILBLAZERS UNITE!</h1>
            <div className="intro-description">
              <h2>Singapore Dreamin’ 2023 coming soon.</h2>
              <p>
                After a hugely successful{" "}
                <a href="/2020-event">Singapore Dreamin’ event in 2020</a> we
                are finally able to announce the next in-person Singapore
                Dreamin’ event, coming mid 2023.
              </p>
              <br />
              <p>
                Get ready for a full day packed with everything Salesforce -
                including inspiring speakers from all over the globe,
                opportunities to learn and share knowledge, networking with
                fellow Salesforce professionals of all levels and backgrounds,
                hours of fun with our sponsor and partner booths and events, and
                even collect some of that sought-after swag!
              </p>
              <br />
              <p>
                We’d like to thank our sponsors, speakers, organisers, attendees
                and supporters, including you, for making this event possible.
              </p>
              <br />
              <p>
                There are still{" "}
                <a href="mailto:singaporedreamin@googlegroups.com">
                  Sponsor Opportunities available
                </a>
              </p>
            </div>
          </div>
          <div className="intro__brand">
            <div className="logo">
              <img src={colouredLogo} alt="Singapore Dreamin logo" />
            </div>
          </div>
        </section>
        <section className="organiser-wrapper">
          <div className="organiser">
            <h2 className="organiser__heading">
              Organising Team <span>/ meet the organising team</span>
            </h2>
            <p style={{ marginTop: 8, maxWidth: 787 }}>
              Our organising team brings with them years of experience with
              Salesforce, the Trailblazing community and managing events. From a
              range of different backgrounds, companies and roles, they bring
              with them a collective passion for the region and Salesforce.
            </p>
            <ul className="organiser__list">
              {organisers.map((organiser, i) => (
                <li className="organiser-item" key={i}>
                  <div className="organiser-item__img">
                    {organiser.image && (
                      <img src={organiser.image} alt="Singapore Dreamin logo" />
                    )}
                  </div>
                  <h3 className="organiser-item__name">{organiser.title}</h3>
                </li>
              ))}
            </ul>
          </div>
        </section>
        <section class="interest-wrapper" id="sponsoring">
          <div class="interest">
            <div class="interest__text">
              <h2 class="interest-heading">Interested in sponsoring?</h2>
              <div class="interest-description">
                <p>
                  Singapore Dreamin is a nonprofit event and so we are seeking
                  sponsorship from the Salesforce community. It’s a great
                  opportunity to get your brand in front of engaged Salesforce
                  customers and there are many ways to get involved.
                </p>
                <p>
                  To finalise your sponsorship or request further information,
                  contact us below:
                </p>
              </div>
              <a
                class="contact-link"
                href="mailto:singaporedreamin@googlegroups.com"
              >
                CONTACT US
              </a>
            </div>
            <div class="interest__featured-img"></div>
          </div>
        </section>
        <section class="event-wrapper">
          <div class="event">
            <div class="event__text">
              <h2 class="interest-heading">Past events</h2>
              <p>Check out previous events.</p>
              <img src="/images/singapore-1.png" alt="singapore event" />
              <p
                style={{
                  textTransform: "uppercase",
                  fontWeight: 500,
                  paddingTop: 13,
                }}
              >
                singapore dreamin <span style={{ fontSize: 16 }}>2020</span>
              </p>
              <a href="/2020-event">Click here</a>
            </div>
          </div>
        </section>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;
